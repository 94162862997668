import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["overlay", "body", "modal", "form", "content", "close", "size"];
  static values = {
    index: String,
    checkSize: Boolean
  }

  connect() {
    this.modalTarget.classList.remove("hidden");
    enter(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", false);
    });
   
    enter(this.bodyTarget);
  }

  show(e) {
    e.preventDefault();

    this.modalTarget.classList.remove("hidden");
    enter(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", false);
    });
   
    enter(this.bodyTarget);
  }

  dismiss(e) {
    e.preventDefault();
    leave(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", true);
    });
    leave(this.bodyTarget).then(() => {
      this.modalTarget.classList.add("hidden");
      this.modalTarget.remove();
    });
  }

  close(e) {
    e.preventDefault();
    leave(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", true);
    });
    leave(this.bodyTarget).then(() => {
      this.modalTarget.classList.add("hidden");
      this.formTarget.reset();
    });
  }

  closeAndAllowDefault(e) {
    leave(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", true);
    });
    leave(this.bodyTarget).then(() => {
      this.modalTarget.classList.add("hidden");
      this.formTarget.reset();
    });
  }

  showFromWindow(e) {
    let index = e.detail.index;
    if (index === this.indexValue) {
      this.show(e);
    }
  }

  showAfterFetch(e) {
    if (this.hasContentTarget) {
      this.contentTarget.innerHTML = e.detail.content;
      this.show(e);
    }
  }

  resetForm(e) {
    this.formTarget.reset();
  }

  closeOnEscape(e) {
    if (!this.modalTarget.classList.contains("hidden")) {
      if (e.key === "Escape") {
        this.close(e);
      }
    }
  }

  closeAndAlert(e) {
    e.preventDefault();
    leave(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", true);
    });
    leave(this.bodyTarget).then(() => {
      this.modalTarget.classList.add("hidden");
      this.formTarget.reset();
    });
    window.dispatchEvent(new CustomEvent("twModalForcedClosed"));
  }

  handleServerError(e) {
    fetch("/message?type=error&msg=Something went wrong. Please try again", {
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html));
    this.closeAndAllowDefault(e);
  }

  submitEnd(event) {
    if (!this.hasFormTarget) return;
    if (event.target != this.formTarget) return;

    if (!event.detail.success) {
      this.handleServerError();
    }
  }
}