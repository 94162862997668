import { ValidationController } from "stimulus-validation";

export default class extends ValidationController {
  static targets = ["properties", "btn", "phone", "email"];

  static rules = {
    date: { presence: { allowEmpty: false } },
    move_in_date: { presence: { allowEmpty: false } },
    customer_name: { presence: { allowEmpty: false } },
    properties: { presence: { allowEmpty: false } },
    status: { presence: { allowEmpty: false } },
    source: { presence: { allowEmpty: false } },
    bedrooms: { presence: { allowEmpty: false } },
    assign: { presence: { allowEmpty: false } },
    building: { presence: { allowEmpty: false } },
    agent: { presence: { allowEmpty: false } }
  };

  static validators = {
    phoneOrEmail: { attributes: ["phoneOrEmail"]}
  }

  phoneOrEmail({attr, value}) {
    if (this.hasPhoneTarget && this.hasEmailTarget) {
      if (this.phoneTarget.value == "" && this.emailTarget.value == "") {
        this.errors.add(attr, "Please include a phone number, email address, or both");
      }
    }
  }

  connect() {
    super.connect();
  }

  refreshAttr(e) {
    this.attributes.dataMap.delete(e.params.attr);
  }

  afterValidate({ el, attr }) {
    this.btnTarget.disabled = false;
    if (this.errors.has(attr)) {
      this.errorMessageEl(el).textContent = this.errorMessage(attr);
      el.classList.add("border-delete-red");
    } else {
      this.errorMessageEl(el).parentNode.removeChild(this.errorMessageEl(el));
      el.classList.remove("border-delete-red");
    }
  }

  resetButton() {
    this.btnTarget.disabled = false;
  }

  errorMessageEl(el) {
    let errorEl = el.closest(".validatable").querySelector(".error-text");
    if (errorEl === null) {
      errorEl = document.createElement("span");
      errorEl.classList = "error-text mt-2 text-sm text-red-600";
      el.closest(".validatable").appendChild(errorEl);
    }
    return errorEl;
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
  }
}
