import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["overlay", "body", "modal", "form", "img"];

  broadcastCarouselInfo() {
    let currentImg = this.element.querySelector(".is-active");
    let imgNode = currentImg.querySelector("img");
    let resize = false;
    if (imgNode) {
        let width = imgNode.clientWidth;
        let height = imgNode.clientHeight;
        if (height > width) {
          resize = true;
        }
      }
    let index = currentImg.dataset.twCarouselModelIndexValue;
     window.dispatchEvent(new CustomEvent("openTwModal", { detail: {
      index: index,
      resize: resize
    } }));
  }
}