import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "form",
  ];

  connect() {
    // solves firefox issue with select boxes on page refresh
    if (this.hasFormTarget) {
      this.formTarget.reset();
    }
  }


  submit(e) {
    this.formTarget.requestSubmit();
    // clearMessages();
    // if (this.formTarget.requestSubmit) {
    //   this.formTarget.requestSubmit();
    // } else {
    //   this.formTarget.submit();
    // }
  }
}
