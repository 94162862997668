import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    detail: Object,
    name: String
  }

  connect() {
    window.dispatchEvent(new CustomEvent(this.nameValue, {
      detail: this.detailValue
    }))
  }
}