import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "lat", "lng", "streetNumber", "street", "city", "state", "zipcode"];

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap();
    }
  }

  initMap() {
    const bounds = {
      north: 42.899837,
      south: 41.148013,
      east: -69.703343,
      west: -73.624396
    };
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, {
      bounds: bounds,
      componentRestrictions: { country: "us" },

    });
    this.autocomplete.setBounds(bounds);
    this.autocomplete.setFields(["address_components", "geometry", "formatted_address"]);
    this.autocomplete.addListener("place_changed", this.placeChanged.bind(this));
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();

    let components = place.address_components;

    if (typeof(components) == "undefined") {
      return;
    }

    components.forEach(component => {
      switch (component.types[0]) {
        case "street_number":
          this.streetNumberTarget.value = component.short_name;
        case "route":
          this.streetTarget.value = component.short_name;
        case "locality":
          this.cityTarget.value = component.short_name;
        case "administrative_area_level_1":
          this.stateTarget.value = component.short_name;
        case "postal_code":
          this.zipcodeTarget.value = component.short_name;
      }
    });
    this.streetNumberTarget.dispatchEvent(new Event("change"));
  }
}