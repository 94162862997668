import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { enter, leave } from "el-transition";


export default class extends Controller {
  static targets = ["container", "transition"];
  static values = {
    present: Boolean
  }

  connect() {
      // setTimeout(() => {
      //   leave(this.transitionTarget).then(() => {
      //     this.transitionTarget.remove()
      //   })
      // }, 5000)
      enter(this.transitionTarget).then(() => {
          setTimeout(() => {
            if (this.hasTransitionTarget) {
              leave(this.transitionTarget).then(() => {
              this.transitionTarget.remove();
              this.element.remove();
            });
            }
          }, 5000);
        });
  }

  display(e) {
    // if there is a target, only run it for that element
    // else run it no matter what
    if (e.detail.target && e.detail.target === this.containerTarget.id) {
      let params = "type=" + e.detail.type + "&msg=" + e.detail.msg;
      Rails.ajax({
        type: "GET",
        url: "/message?" + params,
        success: (data) => {
          this.containerTarget.insertAdjacentHTML(
            "beforeend",
            data.body.innerHTML
          );
        },
      });
    } else if (!e.detail.target) {
      let params = "type=" + e.detail.type + "&msg=" + e.detail.msg;
      Rails.ajax({
        type: "GET",
        url: "/message?" + params,
        success: (data) => {
          this.containerTarget.insertAdjacentHTML(
            "beforeend",
            data.body.innerHTML
          );
        },
      });
    }
  }

  clear(e) {
    this.containerTarget.innerHTML = "";
  }

  notify(e) {
    let params = "type=" + e.detail.type + "&msg=" + e.detail.msg;
    Rails.ajax({
      type: "GET",
      url: "/notify?" + params,
      success: (data) => {
        this.containerTarget.insertAdjacentHTML(
          "beforeend",
          data.body.innerHTML
        );
        enter(this.transitionTarget).then(() => {
          setTimeout(() => {
            if (this.hasTransitionTarget) {
              leave(this.transitionTarget).then(() => {
              this.transitionTarget.remove();
            });
            }
          }, 5000);
        });
      },
    });
  }

  clearNotification(e) {
    leave(this.transitionTarget).then(() => {
      this.transitionTarget.remove();
      this.element.remove();
    })
  }
}
