import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["btn", "specialDeals", "specialDealsSmall"];
  static values = {
    buttontext: String,
  };

  addSpinner() {
    // $(this.btnTarget).prop("disabled", true);
    // $(this.btnTarget).html(
    //   `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
    // );
  }

  removeSpinner() {
    // this.btnTarget.disabled = false;
    // this.btnTarget.innerHTML = this.buttontextValue || "Submit";
  }

  submit(e) {
    let detail = {
      form: this.element,
    };
    if (e.detail && e.detail.useCoords === false) {
      detail.useCoords = false;
    }
    window.dispatchEvent(new CustomEvent("formSubmitted", { detail: detail }));
  }
}
