import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  // static targets = ["input", "content", "error"];
  static values = {
    icon: String,
    hover: String,
    lat: Number,
    lng: Number,
    title: String,
    recenter: Boolean,
    id: String
  }

  connect() {
    if (typeof google !== "undefined") {
      this.createPin();
    }
  }

  disconnect() {
    this.marker.setMap(null);
  }

  createPin() {
    if (typeof this.marker === "undefined") {
      let position = { lat: this.latValue, lng: this.lngValue };
      let marker = new google.maps.Marker({
          map: window.map,
          position: position,
          title: this.titleValue,
          icon: this.icon(),
        });
      this.marker = marker;
      let card = this.element;
      let icon = this.icon();
      let hoverIcon = this.icon(true);
      let originalZ = marker.getZIndex();

      marker.addListener("mouseover", function () {
          marker.setIcon(hoverIcon);
          marker.setZIndex(Date.now());
        });
        marker.addListener("mouseout", function () {
          marker.setIcon(icon);
          marker.setZIndex(originalZ);
        });
        let id = this.idValue;
        marker.addListener("click", function () {
        let width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        // wider than medium breakpoint
        if (width > 1024) {
          
          document.querySelectorAll(".card-link").forEach((card) => {
            card.classList.remove("highlighted");
          });
          document.querySelectorAll(".card-image").forEach((card) => {
            card.classList.remove("highlighted-image");
          });
          let highlightTarget = card.querySelector(".card-link");
          card.querySelector(".card-image").classList.add("highlighted-image");
          highlightTarget.classList.add("highlighted");
          highlightTarget.scrollIntoView();
          
        } else {
          fetch("/" + id, {
            headers: {
              "Accept": "text/vnd.turbo-stream.html"
            }
          }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html));
        }
      });    
      if (this.recenterValue) {
        window.dispatchEvent(new CustomEvent("recenterMapToPin", {
          detail: {
            lat: this.latValue,
            lng: this.lngValue
          }
        }))
      }
    }
  }

  illuminateMarker(e) {
    if (typeof this.marker !== "undefined") {
      this.marker.setIcon(this.icon(true));
      this.marker.setZIndex(Date.now());
    }
  }

  deluminateMarker(e) {
    this.marker.setIcon(this.icon(false));
    this.marker.setZIndex(undefined);
  }

  icon(hover = false) {
    if (hover) {
      return {
        url: this.hoverValue,
        scaledSize: new google.maps.Size(68, 68),
      };
    } else {
      return {
        url: this.iconValue,
        scaledSize: new google.maps.Size(60, 60),
      };
    }
  }
}