export const dispatchMessage = (type, msg) => {
  window.dispatchEvent(
    new CustomEvent("displayMessage", {
      detail: {
        type: type,
        msg: msg,
      },
    })
  );
};

export const clearMessages = () => {
  window.dispatchEvent(new CustomEvent("clearMessages"));
};
