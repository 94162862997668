import { ValidationController } from "stimulus-validation";

export default class extends ValidationController {
  static targets = ["submit", "errorMessage", "helpMessage", "editable", "input", "editButton", "name", "nameInput", "emailInput", "email", "password", "checkbox", "cancelLink"];
  static values = {
    id: Number,
    index: String
  };

  static rules = {
    name: { presence: { allowEmpty: false } },
    email: { presence: { allowEmpty: false }, email: true },
    password: { presence: { allowEmpty: false } },
    role: { presence: { allowEmpty: false } },
  };

  static validators = { validRoles: { attributes: ['combos' ]  },
    newValidRoles: { attributes: ['newcombos' ]  }
  }

  connect() {
    super.connect();
    this.element.reset();
  }

  afterValidate({ el, attr }) {
    if (this.errors.hasAny()) {
      window.dispatchEvent(new CustomEvent("stopLoading"));
    }
    if (this.errors.has(attr)) {
      this.errorMessageEl(el).textContent = this.errorMessage(attr);
      el.classList.add("border-delete-red");
    } else {
      this.errorMessageEl(el).parentNode.removeChild(this.errorMessageEl(el));
      el.classList.remove("border-delete-red");
    }
  }

  errorMessageEl(el) {
    let errorEl = el.closest(".validatable").querySelector(".error-text");
    if (errorEl === null) {
      errorEl = document.createElement("span");
      errorEl.classList = "error-text mt-2 text-sm text-delete-red";
      el.closest(".validatable").appendChild(errorEl);
    }
    return errorEl;
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
  }

  updateNotifications(e) {
    let data = e.detail;
    if (data.type === "errors") {
      this.errorMessageTarget.innerHTML = data.message;
    } else if (data.type === "help") {
    }
  }

  editName(e) {
    e.preventDefault();
    this.toggleVisibility(this.nameInputTarget, this.nameTarget);
  }

  editEmail(e) {
    e.preventDefault();
    this.toggleVisibility(this.emailInputTarget, this.emailTarget);
  }

  validRoles({ attr, value}) {
    let agent = this.element.querySelector("[id^='agent_role_names_agent']");
    let admin = this.element.querySelector("[id^='agent_role_names_admin']");
    let owner = this.element.querySelector("[id^='agent_role_names_owner']");
    let manager = this.element.querySelector("[id^='agent_role_names_manager']");
    let partner = this.element.querySelector("[id^='agent_role_names_partner agent']");
    let ndf = this.element.querySelector("[id^='agent_role_names_ndf']");

    if (owner.checked && manager.checked) {
      this.errors.add(attr, "Cannot select both Owner and Manager");
    } else if (manager.checked && admin.checked) {
      this.errors.add(attr, "Cannot select both Admin and Manager");
    } else if (!owner.checked && !manager.checked && !agent.checked && !admin.checked && !partner.checked && !ndf.checked) {
      this.errors.add(attr, "Must select at least 1 role");
    } else if (partner.checked && (owner.checked || admin.checked || agent.checked || manager.checked)) {
      this.errors.add(attr, "Partner Agents cannot have any other roles");
    }
  }

  newValidRoles({ attr, value}) {
    let agent = this.element.querySelector("#agent_roles_attributes_agent");
    let admin = this.element.querySelector("#agent_roles_attributes_admin");
    let owner = this.element.querySelector("#agent_roles_attributes_owner");
    let manager = this.element.querySelector("#agent_roles_attributes_manager");
    let partner = this.element.querySelector("#agent_roles_attributes_partner_agent");
    let ndf = this.element.querySelector("#agent_roles_attributes_ndf");

    if (owner.checked && manager.checked) {
      this.errors.add(attr, "Cannot select both Owner and Manager");
    } else if (manager.checked && admin.checked) {
      this.errors.add(attr, "Cannot select both Admin and Manager");
    } else if (!owner.checked && !manager.checked && !agent.checked && !admin.checked && !partner.checked && !ndf.checked) {
      this.errors.add(attr, "Must select at least 1 role");
    } else if (partner.checked && (owner.checked || admin.checked || agent.checked || manager.checked)) {
      this.errors.add(attr, "Partner Agents cannot have any other roles");
    }
  }

  toggleVisibility(toShow, toHide) {
    toShow.classList.remove("hidden");
    toHide.classList.add("hidden");
  }

  editForm(e) {
    e.preventDefault();
    this.editableTargets.forEach((editable) => {
      editable.classList.add("hidden");
    })
    this.inputTargets.forEach((input) => {
      input.classList.remove("hidden");
    })
  }

  showEditButtons(e) {
    let index = e.detail.index;
    if (index === this.indexValue) {
      this.editButtonTargets.forEach((target) => {
        target.classList.remove("hidden");
      });
    }
    
  }

  hideEditButtons(e) {
    let index = e.detail.index;
    if (index === this.indexValue) {
      this.editButtonTargets.forEach((target) => {
        target.classList.add("hidden");
      });
      this.cancelLinkTargets.forEach((target) => {
        target.click();
      })
      // this.editableTargets.forEach((editable) => {
      //   editable.classList.remove("hidden");
      // });
      // this.inputTargets.forEach((input) => {
      //   input.classList.add("hidden");
      // });
      // // prevent a changed form from being submitted even after hiding inputs
      // this.nameInputTarget.value = this.nameTarget.textContent.trim();
      // this.emailInputTarget.value = this.emailTarget.textContent.trim();
    }
  }

  displayErrors(e) {
    let data = e.detail;
    if (data.type === "alert" && this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerHTML = data.msg;
    }
  }

  get completedSVG() {
    return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" contentScriptType="text/ecmascript" width="810" zoomAndPan="magnify" contentStyleType="text/css" viewBox="0 0 810 809.999993" height="809.999993" preserveAspectRatio="xMidYMid meet" version="1.0" class="h-4 w-4"><path fill="rgb(39.99939%, 39.99939%, 42.349243%)" d="M 290.421875 491.867188 L 617.378906 165.175781 C 645.519531 137.054688 691.171875 137.054688 719.3125 165.175781 C 747.460938 193.292969 747.460938 238.90625 719.3125 267.035156 L 341.761719 644.277344 C 341.640625 644.40625 341.523438 644.535156 341.394531 644.660156 C 313.246094 672.78125 267.59375 672.78125 239.453125 644.660156 L 225.859375 631.078125 L 225.867188 631.070312 L 90.898438 496.214844 C 62.757812 468.097656 62.757812 422.480469 90.898438 394.363281 C 119.046875 366.238281 164.6875 366.238281 192.835938 394.363281 L 290.421875 491.867188 " fill-opacity="1" fill-rule="evenodd"/></svg>';
  }
}
