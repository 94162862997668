import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "input", "text", "length", "number", "specialChars", "repeatingChars", "confirmation", "confirmHint"];

  connect() {
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    })
    this.rules = [
      {
        regex: /^.{8,30}$/,
        target: this.lengthTarget,
        matchCount: 1
      }, {
        regex: /\d/g,
        target: this.numberTarget,
        matchCount: 2
      }, {
        regex: /[!@#$%*(;,]/g,
        target: this.specialCharsTarget,
        matchCount: 2
      }, {
        regex: /(.)\1+/,
        target: this.repeatingCharsTarget,
        negate: true
      }
    ]
    this.validateFormat.bind(this);
  }

  validateFormat(e) {
    let value;
    if (typeof e === "object") {
      value = e.target.value;
    } else {
      value = e;
    }
    this.rules.forEach((rule) => {
      let checkbox = rule.target.querySelector("input");
      let label = rule.target.querySelector("label");
      if (rule.negate === true) {
        if (rule.regex.test(value)) {
          this.indicateInvalid(checkbox, label);
          return false;
        } else {
          this.indicateValid(checkbox, label);
        }
      } else {
        let results = value.match(rule.regex);
      
        if (results === null || results.length < rule.matchCount) {
          this.indicateInvalid(checkbox, label);
          return false;
        } else {
          this.indicateValid(checkbox, label);
        }
      }
    })
    return true;
  }

  validateAll(e) {
    if (this.validateFormat(this.inputTarget.value) && this.inputTarget.value === this.confirmationTarget.value) {
      return true;
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  validateConfirmation(e) {
    let value = e.target.value;
    if (value !== this.inputTarget.value) {
      this.confirmationTarget.classList.add("border-delete-red");
      this.confirmationTarget.classList.add("focus:border-delete-red");
      this.confirmHintTarget.innerHTML = "Passwords don't match";
      this.confirmHintTarget.classList.remove("hidden");
      return false;
    } else {
      this.confirmationTarget.classList.remove("border-delete-red");
      this.confirmationTarget.classList.remove("focus:border-delete-red");
      this.confirmHintTarget.innerHTML = "";
      this.confirmHintTarget.classList.add("hidden");
      return true;
    }
  }

  indicateValid(checkbox, label) {
    checkbox.classList.remove("border-delete-red");
    checkbox.checked = true;
    label.classList.remove("text-delete-red");
  }

  indicateInvalid(checkbox, label) {
    checkbox.classList.add("border-delete-red");
        checkbox.checked = false;
        label.classList.add("text-delete-red");
  }

  get checkboxes() {
    return this.element.querySelectorAll("input[type='checkbox']");
  }
}