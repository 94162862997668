import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  clear(e) {
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox !== e.target) {
        checkbox.checked = false;
      }
    });
  }
}