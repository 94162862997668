import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "text", "cancelLink", "editLink", "submitButton"];
  static values = {
    id: String,
    attr: String,
    selected: String,
    mailto: String,
    edited: Boolean,
    scrollable: Boolean,
  };

  connect() {
    // handle single click and double click differently
    this.timer = 0;
    this.delay = 200;
    this.prevent = false;
    this.initialValue = this.hasInputTarget ? this.inputTarget.value : "";
  }

  initEdit(e) {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(this.timer);
    this.prevent = true;

    this.editLinkTarget.click();

    if (this.scrollableValue) {
      this.element.classList.remove("overflow-auto");
      this.element.classList.add("overflow-visible");
    }
  }

  endEdit(e) {
    this.hasInputTarget && this.inputTarget.classList.add("hidden");
    this.hasTextTarget && this.textTarget.classList.remove("hidden");
    this.hasCancelLinkTarget && this.cancelLinkTarget.click();
  }

  handleSingleClick(e) {
    this.timer = setTimeout(() => {
      if (!this.prevent) {
        this.openMailToLink();
      }
      this.prevent = false;
    }, this.delay);
  }

  linkToBuilding(e) {
    e.preventDefault();
    e.stopPropagation();
    this.timer = setTimeout(() => {
      if (!this.prevent) {
        window.open(e.target.href, "_blank");
      }
      this.prevent = false;
    }, this.delay);
  }

  openMailToLink() {
    let address;
    if (this.hasMailtoValue) {
      address = this.mailtoValue;
    } else {
      address = "mailto:" + this.textTarget.innerHTML;
    }
    window.open(address, "_blank");
  }

  closeOrCancel(e) {
    // prevent combobox close button from triggering this also
    if (
      this.element.contains(e.target) ||
      e.target.getAttribute("data-combobox-chosen-param")
    ) {
      e.stopImmediatePropagation();
    } else {
      if (this.editedValue) {
        // continue propagation
        return;
      } else {
        this.endEdit(e);
        e.stopImmediatePropagation();
      }
    }
  }

  setEdited(e) {
    this.editedValue = true;
  }

  setOldValue(e) {
    this.initialValue = e.target.value;
  }

  submit(e) {
    // clearMessages();
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    } else {
      this.formTarget.requestSubmit();
    }
    // let isRemote = this.formTarget.hasAttribute("data-remote");
    // if (isRemote) {
    //   Rails.handleRemote.call(this.formTarget, e);
    // } else {
    //   if (this.formTarget.requestSubmit) {

    //   } else {
    //     this.formTarget.submit();
    //   }
    // }
  }

  submitOnBlur(e) {
    if (this.initialValue === e.target.value) {
      this.closeViaCancel(e);
    } else {
      this.submit(e);
    }
  }

  closeViaCancel(e) {
    this.hasCancelLinkTarget && this.cancelLinkTarget.click();
  }
}
