import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["openButton", "slider", "container"];

  connect() {
    this.overflowHidden = document.body.classList.contains("overflow-hidden");
  }

  openSlider() {
    this.containerTarget.classList.remove("hidden");
    if (!this.overflowHidden) {
      document.body.classList.add("overflow-hidden");
    }
    enter(this.sliderTarget);
  }

  closeSlider() {
    leave(this.sliderTarget).then(() => {
      this.containerTarget.classList.add("hidden");
      if (!this.overflowHidden) {
        document.body.classList.remove("overflow-hidden");
      }
    });
  }
}
