import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "details", "icon", "hideable"];
  static values = {
    index: String
  }
  static classes = ["open"];

  connect() {
    this.isOpen = !this.detailsTarget.classList.contains("hidden");
    this.shouldPreventToggle = false;
  }

  toggle() {
    if (this.shouldPreventToggle) {
      this.shouldPreventToggle = false;
      return;
    }
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    if (!this.isOpen) {
      this.iconTarget.classList.remove("rotate-0");
      this.iconTarget.classList.add(this.openClasses);
      this.detailsTarget.classList.remove("hidden");
      this.isOpen = true;
      this.hideableTargets.forEach(target => {
        target.classList.remove("hidden");
      });
      window.dispatchEvent(new CustomEvent("accordionOpened", {
        detail: {
          index: this.indexValue
        }
      }));
    }
  }

  close() {
    if (this.isOpen) {
      this.iconTarget.classList.remove(this.openClasses);
      this.iconTarget.classList.add("rotate-0");
      this.detailsTarget.classList.add("hidden");
      this.isOpen = false;
      this.hideableTargets.forEach(target => {
        target.classList.add("hidden");
      });
      window.dispatchEvent(new CustomEvent("accordionClosed", {
        detail: {
          index: this.indexValue
        }
      }));
    }
  }

  preventToggle(e) {
    this.shouldPreventToggle = true;
  }
}