import Honeybadger from "@honeybadger-io/js";
import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    Honeybadger.configure({
      apiKey: process.env.HONEYBADGER_API_KEY,
      environment: "production"
    });
  }

  checkFetchResponse(e) {
    let fetchResponse = e.detail.fetchResponse;
    if (!fetchResponse.response.ok) {
      this.handleServerError();
    }
  }

  handleServerError() {
    fetch("/message?type=error&msg=Something went wrong. Please try again", {
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html));
  }
}
