import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["overlay", "body", "modal", "form", "content", "close", "size"];
  static values = {
    index: String,
    checkSize: Boolean
  }

  show(e) {
    e.preventDefault();
     if (this.checkSizeValue && e.detail.resize) {
       this.checkSizeAndAdjustClose()
      }
    this.modalTarget.classList.remove("hidden");
    enter(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", false);
    });
   
    enter(this.bodyTarget);
  }

  dismiss(e) {
    e.preventDefault();
    leave(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", true);
    });
    leave(this.bodyTarget).then(() => {
      this.modalTarget.classList.add("hidden");
      this.modalTarget.remove();
    });
  }

  close(e) {
    e.preventDefault();
    leave(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", true);
    });
    leave(this.bodyTarget).then(() => {
      this.modalTarget.classList.add("hidden");
      this.formTarget.reset();
    });
  }

  closeAndAllowDefault(e) {
    leave(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", true);
    });
    leave(this.bodyTarget).then(() => {
      this.modalTarget.classList.add("hidden");
      this.formTarget.reset();
    });
  }

  showFromWindow(e) {
    let index = e.detail.index;
    if (index === this.indexValue) {
      this.show(e);
    }
  }

  showAfterFetch(e) {
    if (this.hasContentTarget) {
      this.contentTarget.innerHTML = e.detail.content;
      this.show(e);
    }
  }

  resetForm(e) {
    this.formTarget.reset();
  }

  closeOnEscape(e) {
    if (!this.modalTarget.classList.contains("hidden")) {
      if (e.key === "Escape") {
        this.close(e);
      }
    }
  }

  closeOnEscapeAndAlert(e) {
    if (!this.modalTarget.classList.contains("hidden")) {
      if (e.key === "Escape") {
        this.closeAndAlert(e);
      }
    }
  }

  closeAndAlert(e) {
    e.preventDefault();
    leave(this.overlayTarget).then(() => {
      this.overlayTarget.setAttribute("aria-hidden", true);
    });
    leave(this.bodyTarget).then(() => {
      this.modalTarget.classList.add("hidden");
      this.formTarget.reset();
    });
    window.dispatchEvent(new CustomEvent("twModalForcedClosed"));
  }

    checkSizeAndAdjustClose() {
      if (this.hasSizeTarget) {
        // let width = this.sizeTarget.clientWidth;
        // let height = this.sizeTarget.clientHeight;
        // if (height > width) {
          this.sizeTarget.classList.add("max-h-3/4-screen");
          this.sizeTarget.classList.remove("w-full")
          this.bodyTarget.classList.add("md:max-w-4xl");
          this.bodyTarget.classList.remove("lg:max-w-fit");
          this.closeTarget.classList.remove("text-white");
          this.closeTarget.classList.add("text-brand-gray");
        // }
      }
      
    }


}