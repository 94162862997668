import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text", "checkbox"];
  
  connect() {
    if (this.checkboxTarget.checked) {
      this.textTargets.forEach(text => {
        text.innerText = text.innerText.replace("+", "");
      });
    } 
  }

  checkStatus(e) {
    if (this.checkboxTarget.checked) {
      this.textTargets.forEach(text => {
        text.innerText = text.innerText.replace("+", "");
      });
    } else {
      this.textTargets.forEach(text => {
        if (!/Studio/.test(text.innerText)) {
          text.innerText = text.innerText + "+";
        }
      });
    }
  }
}
