import Litepicker from "litepicker";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "display"];

  connect() {
    this.picker = new Litepicker({
      element: this.inputTarget,
      inlineMode: true,
      singleMode: false,
    });
    this.picker.on("selected", this.onSelected.bind(this));
  }

  onSelected(date1, date2) {
    window.dispatchEvent(new CustomEvent("datepickerDateSelected"));
    let formattedDate1 = date1.dateInstance.toLocaleDateString("en-US");
    let formattedDate2 = date2.dateInstance.toLocaleDateString("en-US");
    if (this.hasDisplayTarget) {
      this.displayTarget.innerHTML = formattedDate1 + " - " + formattedDate2;
    }
  }
}
