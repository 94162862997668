import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["input", "content", "error"];
  static values = {
    url: String
  }

  submit(e) {
    e.preventDefault();

    let text = this.inputTarget.value;

    if (text === "") {
      return;
    }
    let params = "note[text]=" + text;
    Rails.ajax({
      url: this.urlValue,
      type: "POST",
      data: params,
      success: this.updateContent.bind(this),
      error: this.handleError.bind(this)
    })
  }

  updateContent(data) {
    this.inputTarget.value = "";
    this.contentTarget.innerHTML = data.body.innerHTML;
  }

  handleError(data) {
    Rails.ajax({
      url: "/message?type=error&msg=Something went wrong",
      type: "GET",
      success: this.showError.bind(this)
    })
  }

  showError(data) {
    this.errorTarget.innerHTML = data.body.innerHTML;
  }

  onEnter(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      this.submit(e);
    }
  }


}