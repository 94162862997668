import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown", "btn", "option", "input", "btnText"];

  static values = {
    multi: Boolean,
    multiRemoveCallback: String,
    separator: String,
    focusWholeElement: Boolean
  }

  static classes = ["optionHover", "chip", "close"];

  connect() {
    this.oldValue = this.inputTarget.value;
    this.open = false;
    this.focusedElement = this.inputTarget;
    this.chipValues = [];

    if (this.separatorValue === ":newline") {
      this.separator = "\n";
    } else {
      this.separator = this.separatorValue || "|";
    }
    if (this.multiValue) {
      this.replaceInput();
    }
    
  }

  replaceInput() {
    let classes = this.inputTarget.classList;
    this.multiBox = document.createElement("div");
    
    this.multiBox.classList = classes;
    this.multiInput = document.createElement("input");
    this.multiInput.placeholder = this.inputTarget.placeholder;
    this.multiInput.style.background = "transparent";
    this.multiInput.style.outline = "none";
    this.multiInput.style.padding = "0px";
    this.multiInput.style.minWidth = "50px";
    this.multiInput.style.border = "none";
    this.multiInput.classList.add("flex-1");
    this.multiInput.setAttribute("data-action", this.inputTarget.getAttribute("data-action"));
    if (this.focusWholeElementValue) {
      this.multiBox.setAttribute("data-action", "click->combobox#setFocusToInput:self");
    }
    this.inputTarget.classList.add("hidden");
    this.multiBox.id = this.inputTarget.id + "--multi";
    this.multiBox.classList.add("flex", "flex-wrap");
    
    this.multiBox.appendChild(this.multiInput);
    this.setChips();
    this.inputTarget.parentNode.appendChild(this.multiBox);
    this.focusedElement = this.multiInput;
  }

  setChips() {
    this.chipValues = [];
    if (this.inputTarget.value === "") {
      return;
    }
    let options = this.optionTargets.map((option) => {
      return option.innerText.trim().toLowerCase();
    })
    this.chipValues = Array.from(new Set(this.inputTarget.value.split(this.separator))).filter((chip) => {
      return options.includes(chip.toLowerCase());
    });
    this.chipValues.forEach(chip => {
      this.addChip(chip);
    });
    this.toggleOptions();
  }

  toggleOptions() {
    if (this.multiValue) {
      this.optionTargets.forEach((option) => {
      if (this.chipValues.includes(option.getAttribute("data-combobox-chosen-param"))) {
        option.classList.add("hidden");
      } else {
        option.classList.remove("hidden");
      }
    })
    }
  }

  toggle() {
    this.dropdownTarget.classList.toggle("hidden");
    
    this.open = !this.open;
    if (this.open) {
      this.inputTarget.setAttribute("aria-expanded", "true");
    } else {
      this.toggleOptions();
      this.inputTarget.setAttribute("aria-expanded", "false");
    }
  }

  openDropdown() {
    if (this.open) {
      return;
    }
    this.toggle();
  }

  setFocusToInput(e) {
    e.stopImmediatePropagation();
    this.multiInput.focus();
  }

  changeFocusedElement(e) {
    if (!this.open) { return; }
    let keyCode = e.keyCode;
    let availableOptions = this.optionTargets.filter(option => {
      return !option.classList.contains("hidden");
    })
    if (keyCode === 38) {
      // up arrow
      let index =availableOptions.indexOf(e.target);
      if (index !== 0) {
       availableOptions[index - 1].focus();
      }
    } else if (keyCode === 40) {
      // down arrow
      let index =availableOptions.indexOf(e.target);;
      if (index !==availableOptions.length - 1) {
       availableOptions[index + 1].focus();
      }
    }
  }

  chooseOptionOnEnter(e) {
    let keyCode = e.keyCode;
    if (keyCode === 13) {
      let param = e.params.chosen;
      if (this.multiValue) {
        e.params.chosen = param;
        this.selectMulti(e);
      } else {
        e.params.chosen = param;
        this.changeInputValue(e);
      }
    } else {
      e.stopImmediatePropagation();
    }
  }

  removeOptionOnEnter(e) {
    let keyCode = e.keyCode;
    if (keyCode === 13) {
      let param = e.params.chosen;
      e.params.chosen = param;
      this.removeFromMulti(e);
      if (this.open) {
        this.toggle();
      }
    } else {
      e.stopImmediatePropagation();
    }
    
  }

  changeInputValue(e) {
    let chosen = e.params.chosen;
    if (this.oldValue.toString() === chosen.toString()) {
      e.stopImmediatePropagation();
    } else {
      this.inputTarget.value = chosen;
      this.toggle();
      this.oldValue = chosen;
    }
  }

  changeInputValueOnKey(e) {
    if (e.keyCode === 13) {
      this.inputTarget.blur();
      return;
    } else if (e.type === "blur") {
      e.params.chosen = e.target.value;
      this.changeInputValue(e);
    }
    
  }

  closeOnOutsideClick(e) {
    if (this.element.contains(e.target)) {
      return;
    }
    if (this.open) {
      this.toggle();
    }
  }

  selectMulti(e) {
    let param = e.params.chosen;
    if (this.chipValues.includes(param)) {
      e.stopImmediatePropagation();
      return;
    }
    this.chipValues.push(param);
    this.addChip(param);
    this.inputTarget.value = this.chipValues.join(this.separator);
    this.toggle();
  }

  addChip(text) {
    let span = document.createElement("span");
    span.innerText = text;
    span.classList.add(...this.chipClasses);
    span.id = "combobox-values-" + text;
    let closeButton = document.createElement("span");
    closeButton.innerText = "x";
    closeButton.classList.add(...this.closeClasses);
    // allow callback to run before actually removing the element
    closeButton.setAttribute("data-action", "click->combobox#removeFromMulti keyup->combobox#removeOptionOnEnter:self" + " " + this.multiRemoveCallbackValue + " click->combobox#removeElement");
    closeButton.setAttribute("tabindex", "0");
    closeButton.setAttribute("data-combobox-chosen-param", text);
    span.insertAdjacentElement("beforeend", closeButton);
    this.multiInput.value = "";
    this.multiInput.insertAdjacentElement("beforebegin", span);
    this.toggleOptions();
  }

  removeFromMulti(e) {
    let param = e.params.chosen.toString();
    let el = e.target.parentNode;
    this.chipValues = this.chipValues.filter((chip) => {
      return chip !== param;
    });
    this.inputTarget.value = this.chipValues.join(this.separator);
    el.classList.add("hidden");
    this.toggleOptions();
  }

  removeElement(e) {
    let el = e.target.parentNode;
    el.remove();
  }

  filterOptions(e) {
    let text;
    if (this.multiValue) {
      text = this.multiInput.value.toLowerCase();
    } else {
      text = this.inputTarget.value.toLowerCase();
    }
    this.optionTargets.forEach((option) => {
      let optionValue = option.getAttribute("data-combobox-chosen-param");
      if (!this.chipValues.includes(optionValue) && optionValue.toLowerCase().indexOf(text) > -1) {
        option.classList.remove("hidden");
      } else {
        option.classList.add("hidden");
      }
    })
  }
}
