import { Controller } from "stimulus";
import _ from "underscore";
import Cookies from "js-cookie";


export default class extends Controller {
  static targets = ["tracked"];
  static values = {
    scroll: Boolean
  };

  connect() {
    this.location = this.element.getBoundingClientRect().top;
    this.checkForHighestElement = _.throttle(this.checkForHighestElement, 1000).bind(this);
  }

  scrollToLastLocation() {
    let last = Cookies.get("scrolled_last_location");
    if (last !== null ) {
      this.element.scrollTop = parseInt(last, 10);
      Cookies.remove("scrolled_last_location");
    }
  }

  checkForHighestElement() {
    let top = this.element.scrollTop;
    Cookies.set("scrolled_last_location", top);
  }
}