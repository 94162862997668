import { Controller } from "stimulus";
import { clearMessages } from "../src/messages";

export default class extends Controller {
  static targets = [
    "notes",
    "form",
    "showMoreBtn",
    "detailPane",
    "finalBuildingSelect",
    "agentDropdown",
    "statusDropdown",
    "propertyAlert",
    "cancelLink",
    "panelStatus",
    "resetButton",
    "submitButton"
  ];

  static values = {
    id: String,
    replace: String,
    agentTouched: Boolean
  }

  connect() {
    // solves firefox issue with select boxes on page refresh
    if (this.hasFormTarget) {
      this.formTarget.reset();
    }
    this.initialValues = {};
  }

  disconnect() {
    if (this.hasDetailPaneTarget) {
      this.detailPaneTarget.classList.add("hidden");
      this.showMoreBtnTarget.innerHTML = "More";
    }
  }

  submit(e) {
    clearMessages();
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    } else {
      this.formTarget.requestSubmit();
    }
    // let isRemote = this.formTarget.hasAttribute("data-remote");
    // if (isRemote) {
    //   Rails.handleRemote.call(this.formTarget, e);
    // } else {
    //   if (this.formTarget.requestSubmit) {
        
    //   } else {
    //     this.formTarget.submit();
    //   }
    // }
  }

  submitOnBlur(e) {
    let initialValue = this.initialValues[e.target.id];
    if (initialValue === e.target.value ){
      this.closeViaCancel(e);
    } else {
      this.submit(e);
    }
  }

  submitOnEnter(e) {
    let keyCode = e.keyCode;
    if (keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.submit(e);
    }
  }

  closeViaCancel(e) {
    this.hasCancelLinkTarget && this.cancelLinkTarget.click();
  }

  setOldValue(e) {
    this.initialValues[e.target.id] = e.target.value;
  }

  toggleDetailPane(e) {
    e.preventDefault();

    if (this.detailPaneTarget.classList.contains("hidden")) {
      this.detailPaneTarget.classList.remove("hidden");
      this.showMoreBtnTarget.innerHTML = "Less";
      this.panelStatusTarget.value = "open";
    } else {
      this.detailPaneTarget.classList.add("hidden");
      this.showMoreBtnTarget.innerHTML = "More";
      this.panelStatusTarget.value = "closed";
    }
  }

  changePendingAgent(e) {
    this.agentTouchedValue = true;
    this.setProgressEditable();
  }

  setProgressEditable() {
    if (!this.hasPropertyAlertTarget && this.agentTouchedValue) {
      this.statusDropdownTarget.setAttribute("data-controller", "listbox flyout");
    }
  }

  submitAfterTransfer(e) {
    if (e.detail.id.toString() === this.idValue) {
      this.submit(e);
    }
  }

  handleServerError() {
    fetch("/message?type=error&msg=Something went wrong. Please try again", {
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html));
    this.resetButtonTarget.click();
  }

  submitEnd(event) {
    if (event.target != this.element) return;

    if (!event.detail.success) {
      this.handleServerError();
    }
  }
}
