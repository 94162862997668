import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["dropdown", "btn", "anchor", "rotate"];
  static values = {
    changebackground: Boolean,
    setAnchorPosition: Boolean
  }
  static classes = ["rotate"]

  connect() {
    this.isOpen = this.dropdownTarget.classList.contains("opaque");    
  }

  toggle(e) {
    if (this.isOpen) {
      this.close(e);
    } else {
      this.open(e);
    }
    
  }

  open(e) {
    this.setAnchorPosition();
    enter(this.dropdownTarget).then(() => {
      this.btnTarget.setAttribute("aria-expanded", "true");
      this.isOpen = true;
      this.hasRotateTarget && this.rotateTarget.classList.add(this.rotateClasses);
    });
    if (this.changebackgroundValue) {
      this.btnTarget.classList.add("bg-rw-background");
      this.btnTarget.classList.remove("bg-transparent");
    }
  }

  close(e) {
    leave(this.dropdownTarget).then(() => {
      this.btnTarget.setAttribute("aria-expanded", "false");
      this.isOpen = false;
      this.removeAnchorPosition();
      this.hasRotateTarget && this.rotateTarget.classList.remove(this.rotateClasses);
    });
    if (this.changebackgroundValue) {
      this.btnTarget.classList.add("bg-transparent");
      this.btnTarget.classList.remove("bg-rw-background");
    }
  }

  closeOnOutsideClick(e) {
    if (
      e.target == this.element ||
      this.element.contains(e.target) ||
      e.target.classList.contains("day-item") ||
      e.target.classList.contains("button-next-month") ||
      e.target.classList.contains("button-previous-month")
    ) {
      return;
    }

    if (this.isOpen) {
      this.close(e);
    }
  }

  closeViaKeyboard(e) {
    if (e.key === "Escape") {
      this.close(e);
    }
  }

  setAnchorPosition() {
    let width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (this.setAnchorPositionValue && this.hasAnchorTarget && width < 1024) {
      let containerPos = 0;
      let containerEl = document.getElementById("map-target");
      if (containerEl) {
        containerPos = containerEl.getBoundingClientRect().left;
      }
      let dropdownPos = this.btnTarget.getBoundingClientRect();
      let btnWidth = this.btnTarget.clientWidth;
      let pos = dropdownPos.right - (btnWidth / 2);
      pos = pos - containerPos;
      this.anchorTarget.style.left = pos + "px";
      this.anchorTarget.style.transform = "translateX(-75%) translateY(-90%)";
    }
  }

  removeAnchorPosition() {
    if (this.setAnchorPositionValue && this.hasAnchorTarget) {
      this.anchorTarget.style.removeProperty("left");
      this.anchorTarget.style.removeProperty("transform");
    }
  }
}
