import { Controller } from "stimulus";
import { enter, leave } from "el-transition";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["overlay", "body"];

  connect() {
    this.element.classList.remove("hidden");
    enter(this.overlayTarget);
    enter(this.bodyTarget);
  }

  dismiss() {
    leave(this.overlayTarget);
    leave(this.bodyTarget).then(() => {
      this.element.classList.add("hidden");
      this.element.remove();
      Cookies.set("show_onboarding", "false", { expires: 720 });
    });
  }
}
