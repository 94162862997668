import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown", "btn", "option", "input", "btnText"];

  static values = {
    label: String,
  };

  static classes = ["highlight"];

  connect() {
    if (this.hasInputTarget) {
      this.oldValue = this.inputTarget.value;
    }
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  highlightOption(e) {
    e.target.classList.add(this.highlightClass);
    e.target.classList.remove("text-brand-gray");
    let checkmark = e.target.getElementsByClassName("checkmark")[0];
    if (checkmark) {
      // checkmark.classList.add("text-white");
      checkmark.classList.remove("text-light-primary");
    }
  }

  removeHighlight(e) {
    e.target.classList.add("text-brand-gray");
    e.target.classList.remove(this.highlightClass);
    let checkmark = e.target.getElementsByClassName("checkmark")[0];
    if (checkmark) {
      checkmark.classList.add("text-light-primary");
      // checkmark.classList.remove("text-white");
    }
  }

  changeSelection(e) {
    let selected = e.currentTarget;
    if (this.hasInputTarget) {
      this.oldValue = this.inputTarget.value;
    }
    if (this.hasBtnTextTarget) {
      this.oldText = this.btnTextTarget.innerHTML;
    } else if (this.hasBtnTarget) {
      this.oldText = this.btnTarget.innerHTML;
    }

    this.optionTargets.forEach((option) => {
      if (selected.dataset.listboxOption === option.dataset.listboxOption) {
        let checkmark = option.getElementsByClassName("checkmark")[0];
        if (checkmark) {
          checkmark.classList.remove("hidden");
        }
      } else {
        let checkmark = option.getElementsByClassName("checkmark")[0];
        if (checkmark) {
          checkmark.classList.add("hidden");
        }
      }
    });
    if (this.hasInputTarget) {
      this.inputTarget.value = selected.dataset.listboxOptionValue;
    }
    let displayValue;
    if (typeof selected.dataset.listboxOptionDisplayValue !== "undefined") {
      displayValue = selected.dataset.listboxOptionDisplayValue;
    } else {
      displayValue = selected.dataset.listboxOptionValue;
    }
    if (displayValue === "") {
      displayValue = this.labelValue;
    }
    if (this.hasBtnTextTarget) {
      this.btnTextTarget.innerHTML =
        "<span class='block truncate min-h-125rem'>" + displayValue + "</span>";
    } else if (this.hasBtnTarget) {
      this.btnTarget.innerHTML =
        "<span class='block truncate min-h-125rem'>" + displayValue + "</span>";
    }

    // window.dispatchEvent(new CustomEvent("leadsFormChanged"));
  }

  openOptions(e) {
    let keyCode = e.keyCode;
    if (keyCode === 13) {
      // enter key
      this.setFocus();
    }
  }

  setFocus(e) {
    let hasSelected = false;
    this.optionTargets.forEach((option) => {
      let checkmark = option.getElementsByClassName("checkmark")[0];
      if (checkmark) {
        if (!checkmark.classList.contains("hidden")) {
          hasSelected = true;
          option.focus();
        }
      }
    });
    if (!hasSelected) {
      this.optionTargets[0].focus();
    }
  }

  handleKeyPress(e) {
    let keyCode = e.keyCode;
    if (keyCode === 38) {
      // up arrow
      let index = this.optionTargets.indexOf(e.target);
      if (index !== 0) {
        this.optionTargets[index - 1].focus();
      }
    } else if (keyCode === 40) {
      // down arrow
      let index = this.optionTargets.indexOf(e.target);
      if (index !== this.optionTargets.length - 1) {
        this.optionTargets[index + 1].focus();
      }
    } else if (keyCode === 13) {
      this.changeSelection(e);
    }
  }

  filterOptions(e) {
    let options = this.optionTargets;
    options.forEach((option) => {
      let displayValue;
      if (typeof option.dataset.listboxOptionDisplayValue !== "undefined") {
        displayValue = option.dataset.listboxOptionDisplayValue;
      } else {
        displayValue = option.dataset.listboxOptionValue;
      }
      if (
        displayValue.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      ) {
        option.classList.remove("hidden");
      } else {
        option.classList.add("hidden");
      }
    });
  }

  revertSelection(e) {
    if (this.hasInputTarget && this.oldValue !== this.inputTarget.value) {
      this.inputTarget.value = this.oldValue;
      if (this.hasBtnTextTarget) {
        this.btnTextTarget.innerHTML = this.oldText;
      } else {
        this.btnTarget.innerHTML = this.oldText;
      }
    }
  }

  triggerChange(e) {
    if (this.hasInputTarget) {
      this.inputTarget.dispatchEvent(new Event("change"));
    }
  }
}
