import { Controller } from "stimulus";
import Hammer from "hammerjs";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["drawer"];

  connect() {
    this.hammer = new Hammer(this.element);
    this.hammer.get("pan").set({ direction: Hammer.DIRECTION_DOWN });
    this.hammer.on("panend", this.closeOnPanGesture.bind(this));
    this.open();
  }

  open() {
    enter(this.element);
  }

  close() {
    leave(this.element);
  }

  closeOnOutsideClick(e) {
    if (
      e.target == this.element ||
      this.element.contains(e.target)
    ) {
      return;
    }
    leave(this.element);
  }

  closeOnPanGesture(e) {
    leave(this.element);
  }

  closeOnResize() {
    let width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width > 1024) {
      this.close();
    }
  }
}
