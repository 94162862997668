import { Controller } from "stimulus";

// this controller has a single use, for toggling between 'map' and 'list' views on the buildings#index page
// not my favorite, but it works for now.

export default class extends Controller {
  static targets = ["toggle", "defaultDisplay", "secondaryDisplay"];
  static values = {
    defaultText: String,
    secondaryText: String,
  };

  connect() {
    this.active = "default";
    // if (this.hasDetailTarget) {
    //   this.hammer = new Hammer(this.detailTarget);
    //   this.hammer.get("pan").set({ direction: Hammer.DIRECTION_DOWN });
    //   this.hammer.on("panend", this.closeOnPanGesture.bind(this));
    // }
  }

  toggleDisplays(e) {
    e.preventDefault();
    if (this.active === "default") {
      this.defaultDisplayTarget.style.display = "none";
      this.secondaryDisplayTarget.style.display = "block";
      this.active = "secondary";
      let svg =
        '<svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg><svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>';
      this.toggleTarget.innerHTML =
        svg + `<span>${this.secondaryTextValue}</span>`;
    } else if (this.active === "secondary") {
      this.secondaryDisplayTarget.style.display = "none";
      this.defaultDisplayTarget.style.display = "block";
      this.active = "default";
      let svg =
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" zoomAndPan="magnify" viewBox="0 0 525 675.000002" preserveAspectRatio="xMidYMid meet" version="1.0" class="h-6 w-auto"><defs><clipPath id="id1"><path d="M 63.296875 52.421875 L 461.703125 52.421875 L 461.703125 621.578125 L 63.296875 621.578125 Z M 63.296875 52.421875 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#id1)"><path fill="#ffffff" d="M 262.5 52.421875 C 152.511719 52.421875 63.296875 141.636719 63.296875 251.628906 C 63.296875 401.03125 262.5 621.578125 262.5 621.578125 C 262.5 621.578125 461.703125 401.03125 461.703125 251.628906 C 461.703125 141.636719 372.488281 52.421875 262.5 52.421875 Z M 262.5 322.773438 C 223.226562 322.773438 191.355469 290.898438 191.355469 251.628906 C 191.355469 212.355469 223.226562 180.484375 262.5 180.484375 C 301.773438 180.484375 333.644531 212.355469 333.644531 251.628906 C 333.644531 290.898438 301.773438 322.773438 262.5 322.773438 Z M 262.5 322.773438 " fill-opacity="1" fill-rule="nonzero"/></g></svg>';
      this.toggleTarget.innerHTML =
        svg + `<span>${this.defaultTextValue}</span>`;
    }
  }

  handleResize() {
    let width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width > 1024) {
      this.defaultDisplayTarget.style.display = "block";
      this.secondaryDisplayTarget.style.display = "block";
      this.active = "default";
      let svg =
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" zoomAndPan="magnify" viewBox="0 0 525 675.000002" preserveAspectRatio="xMidYMid meet" version="1.0" class="h-6 w-auto"><defs><clipPath id="id1"><path d="M 63.296875 52.421875 L 461.703125 52.421875 L 461.703125 621.578125 L 63.296875 621.578125 Z M 63.296875 52.421875 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#id1)"><path fill="#ffffff" d="M 262.5 52.421875 C 152.511719 52.421875 63.296875 141.636719 63.296875 251.628906 C 63.296875 401.03125 262.5 621.578125 262.5 621.578125 C 262.5 621.578125 461.703125 401.03125 461.703125 251.628906 C 461.703125 141.636719 372.488281 52.421875 262.5 52.421875 Z M 262.5 322.773438 C 223.226562 322.773438 191.355469 290.898438 191.355469 251.628906 C 191.355469 212.355469 223.226562 180.484375 262.5 180.484375 C 301.773438 180.484375 333.644531 212.355469 333.644531 251.628906 C 333.644531 290.898438 301.773438 322.773438 262.5 322.773438 Z M 262.5 322.773438 " fill-opacity="1" fill-rule="nonzero"/></g></svg>';
      this.toggleTarget.innerHTML =
        svg + `<span>${this.defaultTextValue}</span>`;
    } else if (this.active === "default") {
      this.secondaryDisplayTarget.style.display = "none";
    }
  }
}
