import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["btn"];
  static values = {
    inactiveText: String,
    active: Boolean
  }
  connect() {
    this.originalText = this.btnTarget.innerHTML;
    if (this.activeValue) {
      setTimeout(() => {
        this.btnTarget.innerHTML = this.inactiveTextValue;
      }, 2000);
    }
  }

  start(e) {
    this.btnTarget.innerHTML =
      '<div class="animate-spin rounded-full h-6 w-6 border-b-2 border-current"></div>';
  }

  end(e) {
    this.btnTarget.innerHTML = this.originalText;
  }
}
