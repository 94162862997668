import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "bg", "foreground"];

  static values = {
    current: Boolean
  }

  connect() {
    this.isActive = this.currentValue;
  }

  toggle() {
    if (this.isActive) {
      this.bgTarget.classList.remove("bg-primary");
      this.bgTarget.classList.add("bg-white");
      this.foregroundTarget.classList.remove("translate-x-5");
      this.foregroundTarget.classList.add("translate-x-0");
      this.foregroundTarget.classList.add("bg-lighter-gray");
      this.foregroundTarget.classList.remove("bg-white");
      this.isActive = false;
      this.inputTarget.checked = false;
      this.inputTarget.dispatchEvent(new CustomEvent("change"));
    } else {
      
      this.bgTarget.classList.add("bg-primary");
      this.bgTarget.classList.remove("bg-white");
      this.foregroundTarget.classList.add("translate-x-5");
      this.foregroundTarget.classList.remove("translate-x-0");
      this.foregroundTarget.classList.add("bg-white");
      this.foregroundTarget.classList.remove("bg-lighter-gray");
      this.isActive = true;
      this.inputTarget.checked = true;
      this.inputTarget.dispatchEvent(new CustomEvent("change"));
    }
  }
}
