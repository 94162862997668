import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
} from "../helpers";

export default class extends Controller {
  static targets = ["input", "hideable"];
  static values = {
    maxFiles: { type: Number, default: 1 },
    url: String,
    maxFileSize: { type: Number, default: 1 },
    acceptedFiles: Array,
    useRemoveLinks: { type: Boolean, default: true },
    httpMethod: { type: String, default: "post" },
    previewContainer: { type: String, default: "#dropzone-preview-container" },
  };

  connect() {
    this.dropzone = this.createDropzone();
    this.bindEvents();
    Dropzone.autoDiscover = false;
  }

  bindEvents() {
    this.dropzone.on("addedfile", (file) => {
      if (this.hasHideableTarget) {
        this.hideableTargets.forEach((target) => {
          target.classList.add("hidden");
        });
      }
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropzone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
      if (this.hasHideableTarget && this.dropzone.files.length == 0) {
        this.hideableTargets.forEach((target) => {
          target.classList.remove("hidden");
        });
      }
    });

    this.dropzone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });
  }

  createDropzone() {
    return new Dropzone(this.element, {
      autoQueue: false,
      url: this.urlValue,
      method: this.httpMethodValue,
      headers: this.headers,
      maxFiles: this.maxFilesValue,
      maxFilesize: this.maxFileSizeValue,
      acceptedFiles: this.acceptedFilesValue.join(","),
      addRemoveLinks: this.useRemoveLinksValue,
      thumbnailWidth: 120,
      thumbnailHeight: 120,
      previewsContainer: this.previewContainerValue,
      previewTemplate: this.previewTemplate,
      addRemoveLinks: false,
    });
  }

  bypassNativeFileField(e) {
    e.preventDefault();
    this.dropzone.hiddenFileInput.click();
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get previewTemplate() {
    return `
      <div class="dz-preview dz-file-preview flex flex-col w-full">
        <div class="flex items-center">
          <div class="dz-details flex items-center w-full">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" zoomAndPan="magnify" viewBox="0 0 375 374.999991" preserveAspectRatio="xMidYMid meet" version="1.0" class="h-6 w-6 mr-2"><defs><clipPath id="d42061b213"><path d="M 67.5 37.5 L 307.5 37.5 L 307.5 337.5 L 67.5 337.5 Z M 67.5 37.5 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#d42061b213)"><path fill="currentColor" d="M 233.46875 37.773438 L 107.746094 37.773438 C 85.527344 37.773438 67.449219 55.851562 67.449219 78.070312 L 67.449219 296.929688 C 67.449219 319.148438 85.527344 337.226562 107.746094 337.226562 L 267.257812 337.226562 C 289.476562 337.226562 307.554688 319.152344 307.554688 296.929688 L 307.554688 120.574219 Z M 239.339844 76.191406 L 273.183594 114.015625 L 239.339844 114.015625 Z M 267.253906 315.988281 L 107.746094 315.988281 C 97.238281 315.988281 88.6875 307.441406 88.6875 296.929688 L 88.6875 78.070312 C 88.6875 67.558594 97.238281 59.011719 107.746094 59.011719 L 218.101562 59.011719 L 218.101562 135.253906 L 286.3125 135.253906 L 286.3125 296.929688 C 286.3125 307.441406 277.761719 315.988281 267.253906 315.988281 Z M 267.253906 315.988281 " fill-opacity="1" fill-rule="nonzero"/></g><path fill="currentColor" d="M 111.484375 252.460938 L 263.511719 252.460938 L 263.511719 273.699219 L 111.484375 273.699219 Z M 111.484375 252.460938 " fill-opacity="1" fill-rule="nonzero"/><path fill="currentColor" d="M 111.484375 206.308594 L 263.511719 206.308594 L 263.511719 227.546875 L 111.484375 227.546875 Z M 111.484375 206.308594 " fill-opacity="1" fill-rule="nonzero"/><path fill="currentColor" d="M 111.484375 160.160156 L 263.511719 160.160156 L 263.511719 181.402344 L 111.484375 181.402344 Z M 111.484375 160.160156 " fill-opacity="1" fill-rule="nonzero"/></svg>
            <div class="dz-filename">
              <span data-dz-name></span>
              
            </div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          <div data-dz-remove class="cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" zoomAndPan="magnify" viewBox="0 0 375 374.999991" preserveAspectRatio="xMidYMid meet" version="1.0" class="h-6 w-6 ml-2"><path fill="currentColor" d="M 327.226562 327.226562 C 316.945312 337.503906 300.28125 337.503906 290.003906 327.226562 L 47.773438 84.996094 C 37.496094 74.71875 37.496094 58.054688 47.773438 47.773438 C 58.054688 37.496094 74.71875 37.496094 84.996094 47.773438 L 327.226562 290.003906 C 337.503906 300.28125 337.503906 316.945312 327.226562 327.226562 " fill-opacity="1" fill-rule="nonzero"/><path fill="currentColor" d="M 47.773438 327.226562 C 37.496094 316.945312 37.496094 300.28125 47.773438 290.003906 L 290.003906 47.773438 C 300.28125 37.496094 316.945312 37.496094 327.226562 47.773438 C 337.503906 58.054688 337.503906 74.71875 327.226562 84.996094 L 84.996094 327.226562 C 74.71875 337.503906 58.054688 337.503906 47.773438 327.226562 " fill-opacity="1" fill-rule="nonzero"/></svg>
          </div>
        </div>
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
      </div>
    `;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.urlValue, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) => {
      this.uploadRequestDidProgress(event);
    });
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropzone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropzone.emit("error", this.file, error);
    this.source.dropzone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropzone.emit("success", this.file);
    this.source.dropzone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}
