import { ValidationController } from "stimulus-validation";
import { I18n } from "i18n-js/dist/import/I18n";

export default class extends ValidationController {
  static targets = [
    "submit",
    "errorMessage",
    "helpMessage",
    "agent",
    "occupation",
    "studentOptions",
    "studentOccupation",
    "studentLevel",
    "internationalStatus",
    "specifyField",
  ];
  static values = {
    id: Number,
    locale: { type: String, default: "en" },
  };

  static i18n = new I18n({
    en: {
      EMAIL: {
        REQUIRED: "^Email can't be blank",
      },
      FIRST_NAME: {
        REQUIRED: "^First name can't be blank",
      },
      LAST_NAME: {
        REQUIRED: "^Last name can't be blank",
      },
      PHONE: {
        REQUIRED: "^Phone can't be blank",
      },
      MOVE_IN_DATE: {
        REQUIRED: "^Move-in date can't be blank",
      },
      MAX_BUDGET: {
        REQUIRED: "^Max budget can't be blank",
      },
      BEDROOMS: {
        REQUIRED: "^Bedrooms can't be blank",
      },
      REFERRAL: {
        REQUIRED: "^Referral can't be blank",
      },
      OCCUPATION: {
        REQUIRED: "Occupation can't be blank",
      },
    },
    zh: {
      EMAIL: {
        REQUIRED: "^邮箱不可为空白",
      },
      FIRST_NAME: {
        REQUIRED: "^名字不可为空白",
      },
      LAST_NAME: {
        REQUIRED: "^姓氏不可为空白",
      },
      PHONE: {
        REQUIRED: "^电话不可为空白",
      },
      MOVE_IN_DATE: {
        REQUIRED: "^入住日期不可为空白",
      },
      MAX_BUDGET: {
        REQUIRED: "^最高预算不可为空白",
      },
      BEDROOMS: {
        REQUIRED: "^卧室数量不可为空白",
      },
      REFERRAL: {
        REQUIRED: "^推荐人不可为空白",
      },
      OCCUPATION: {
        REQUIRED: "职业不可为空白",
      },
    },
  });

  // settings the rules in the connect method so that the translations are picked up
  // when needed
  static rules = {};

  static validators = {
    validOccupation: { attributes: ["occupation"] },
    validStudentLevel: { attributes: "student_level" },
    validInternationalStatus: { attributes: "international_status" },
  };

  connect() {
    this.constructor.i18n.locale = this.localeValue;
    this.constructor.rules = {
      first_name: {
        presence: {
          allowEmpty: false,
          message: this.constructor.i18n.t("FIRST_NAME.REQUIRED"),
        },
      },
      last_name: {
        presence: {
          allowEmpty: false,
          message: this.constructor.i18n.t("LAST_NAME.REQUIRED"),
        },
      },
      email: {
        presence: {
          allowEmpty: false,
          message: this.constructor.i18n.t("EMAIL.REQUIRED"),
        },
        email: true,
      },
      phone: {
        presence: {
          allowEmpty: false,
          message: this.constructor.i18n.t("PHONE.REQUIRED"),
        },
      },
      referral: {
        presence: {
          allowEmpty: false,
          message: this.constructor.i18n.t("REFERRAL.REQUIRED"),
        },
      },
      move_in_date: {
        presence: {
          allowEmpty: false,
          message: this.constructor.i18n.t("MOVE_IN_DATE.REQUIRED"),
        },
      },
      agent: { presence: { allowEmpty: false } },
      bedrooms: {
        presence: {
          allowEmpty: false,
          message: this.constructor.i18n.t("BEDROOMS.REQUIRED"),
        },
      },
      max_budget: {
        presence: {
          allowEmpty: false,
          message: this.constructor.i18n.t("MAX_BUDGET.REQUIRED"),
        },
      },
    };
    super.connect();
  }

  afterValidate({ el, attr }) {
    if (this.errors.hasAny()) {
      window.dispatchEvent(new CustomEvent("stopLoading"));
    }
    if (this.errors.has(attr)) {
      this.errorMessageEl(el).textContent = this.errorMessage(attr);
      el.classList.add("border-delete-red");
    } else {
      this.errorMessageEl(el).parentNode.removeChild(this.errorMessageEl(el));
      el.classList.remove("border-delete-red");
    }
  }

  errorMessageEl(el) {
    let errorEl = el.closest(".validatable").querySelector(".error-text");
    if (errorEl === null) {
      errorEl = document.createElement("span");
      errorEl.classList = "error-text mt-2 text-sm text-red-600";
      el.closest(".validatable").appendChild(errorEl);
    }
    return errorEl;
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
  }

  updateNotifications(e) {
    let data = e.detail;
    if (data.type === "errors") {
      this.errorMessageTarget.innerHTML = data.message;
    } else if (data.type === "help") {
      // this.helpMessageTarget.innerHTML = data.message;
    }
    // if (data.building === this.idValue) {
    //   this.errorMessageTarget.innerHTML = "";
    //   this.helpMessageTarget.innerHTML = "";
    // }
  }

  updateAgentField(e) {
    if (e.target.value === "Yes" && this.hasAgentTarget) {
      this.agentTarget.setAttribute("data-attr", "contact-form.agent");
      this.agentTarget.setAttribute(
        "data-action",
        "blur->contact-form#validate change->contact-form#validate"
      );
    } else {
      this.agentTarget.removeAttribute("data-attr");
      this.agentTarget.removeAttribute("data-action");
      this.errorMessageEl(this.agentTarget).parentNode.removeChild(
        this.errorMessageEl(this.agentTarget)
      );
      this.agentTarget.classList.remove("border-delete-red");

      this.attributes.dataMap.delete("agent");
      this.errors.clearAttribute("agent");
    }
  }

  toggleAgentFieldDisabled(e) {
    if (e.target.value === "Yes") {
      this.agentTarget.removeAttribute("disabled");
      this.agentTarget.classList.remove("text-brand-gray-400");
    } else {
      this.agentTarget.setAttribute("disabled", true);
      this.agentTarget.value = "";
      this.agentTarget.classList.add("text-brand-gray-400");
    }
  }

  toggleSpecifyFieldDisabled(e) {
    if (e.target.value === "Other") {
      this.specifyFieldTarget.removeAttribute("disabled");
      this.specifyFieldTarget.classList.remove(
        "placeholder:text-brand-gray-400"
      );
    } else {
      this.specifyFieldTarget.setAttribute("disabled", true);
      this.specifyFieldTarget.value = "";
      this.specifyFieldTarget.classList.add("placeholder:text-brand-gray-400");
    }
  }

  validOccupation({ attr, value }) {
    let count = 0;
    this.occupationTargets.forEach((occ) => {
      if (occ.checked) {
        count += 1;
      }
    });
    if (count !== 1) {
      this.errors.add(attr, this.constructor.i18n.t("OCCUPATION.REQUIRED"));
    }
  }

  validStudentLevel({ attr, value }) {
    let count = 0;
    if (!this.studentOccupationTarget.checked) {
      return;
    }
    this.studentLevelTargets.forEach((level) => {
      if (level.checked) {
        count += 1;
      }
    });
    if (count !== 1) {
      this.errors.add(attr, "Pleae make a selection");
    }
  }

  validInternationalStatus({ attr, value }) {
    let count = 0;
    if (!this.studentOccupationTarget.checked) {
      return;
    }
    this.internationalStatusTargets.forEach((level) => {
      if (level.checked) {
        count += 1;
      }
    });
    if (count !== 1) {
      this.errors.add(attr, "Pleae make a selection");
    }
  }

  toggleStudentOptions(e) {
    if (e.params.occupation === "Student") {
      this.studentOptionsTarget.classList.remove("h-0");
      this.studentOptionsTarget.classList.add("h-auto");
    } else {
      this.studentOptionsTarget.classList.remove("h-auto");
      this.studentOptionsTarget.classList.add("h-0");
    }
  }
}
