import { Controller } from "stimulus";
import { debounce } from "underscore";

export default class extends Controller {
  static targets = ["btn", "content"];
  static classes = ["visible"];
  static values = {
    setHeight: Boolean,
    separator: String
  }

  initialize() {
    this.showButton = debounce(this.showButton, 300).bind(this);
  }

  connect() {
    this.isOpen = false;
    this.showButton();
  }

  expand() {
    this.contentTarget.classList.remove("truncate");
    if (this.setHeightValue) {
      let height = this.element.offsetHeight;
      this.element.style.height = height + "px";
    }
    if (this.hasSeparatorValue) {
      this.contentTarget.innerText = this.contentTarget.innerText.replaceAll(this.separatorValue, "\n");
    }
    this.contentTarget.classList.add(...this.visibleClasses);

    this.btnTarget.innerHTML = "Less";
    if (this.btnTarget.nodeName == "INPUT") {
      // button_to creates an input instead of a button or link
      this.btnTarget.value = "Less";
    }
    this.isOpen = true;
  }

  contract() {
    if (this.hasSeparatorValue) {
      this.contentTarget.innerText = this.contentTarget.innerText.replaceAll("\n", this.separatorValue);
    }
    this.contentTarget.classList.remove(...this.visibleClasses);
    this.contentTarget.classList.add("truncate");
   
    this.btnTarget.innerHTML = "More";
    if (this.btnTarget.nodeName == "INPUT") {
      // button_to creates an input instead of a button or link
      this.btnTarget.value = "More";
    }
    this.isOpen = false;
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isOpen) {
      this.contract();
    } else {
      this.expand();
    }
  }

  isTruncated() {
    return this.contentTarget.offsetWidth < this.contentTarget.scrollWidth;
  }

  showButton(e) {
    if (this.isTruncated()) {
      this.btnTarget.classList.remove("hidden");
    } else {
      this.btnTarget.classList.add("hidden");
    }
  }
}
