import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    identifier: String,
    remove: Boolean,
    wrap: Boolean
  }

  connect() {
    let parent = document.getElementById(this.identifierValue);
    if (this.wrapValue) {
      parent.setAttribute("id", Date.now());
      let newFrame = document.createElement("turbo-frame");
      newFrame.setAttribute("id", this.identifierValue);
      parent.parentNode.insertBefore(newFrame, parent);
      newFrame.appendChild(parent);
      newFrame.setAttribute("src", this.urlValue);
    } else {
      parent.setAttribute("src", this.urlValue);
    }
    if (this.removeValue) { 
      this.element.remove();
    }
  }
}
