import { Controller } from "stimulus";
import { clearMessages } from "../src/messages";
import { serializeArray } from "../helpers";
// import {Turbo} from "@hotwired/turbo-rails";

const mapStyles = [
  {
    featureType: "poi.business",
    elementType: "labels",

    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels",

    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

export default class extends Controller {
  // markerData target can be a different element from map target
  // useful when loading the map via render_async, so we don't
  // have to initiate the map twice and get billed twice for it
  static targets = [
    "map",
    "markerData",
    "cardContainer",
    "latMin",
    "latMax",
    "lngMin",
    "lngMax",
    "currentZoom",
    "centerLat",
    "centerLng",
    "form",
  ];
  static values = {
    loadData: Boolean,
    markersData: String,
    lat: String,
    lng: String,
    zoom: String,
    pin: String,
    hoverPin: String,
    page: String,
    loadPartners: Boolean
  };

  connect() {
    this.activeRequest = null;
    this.scrollToMarker = true;
    if (!this.isPreview) {
      if (typeof this.markers === "undefined") {
        this.markers = [];
      }
      if (typeof this.partnerMarkers === "undefined") {
        this.partnerMarkers = [];
      }
      if (typeof this.infoWindows === "undefined") {
        this.infoWindows = [];
      }
      if (typeof google !== "undefined") {
        this.initMap();
      }
    }
  }

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbolinks-preview");
  }

  async fetchPartners() {
    let results = await fetch("/partners", {
      headers: {
        "Content-Type": "Application/json"
      }
    });
    let json = await results.json();
    this.setPartnerMarkers(json);
  }

  load(e) {
    if (typeof e !== "undefined") {
      e.preventDefault();
    };
    this.removeBuildingCardRows();
    clearMessages();
    this.hasCardContainerTarget && this.cardContainerTarget.insertAdjacentHTML(
      "beforeend",
      '<div class="flex flex-col md:grid md:grid-cols-2 gap-4"><div class="map-skeleton relative flex flex-col rounded-lg shadow hover:shadow-2xl overflow-hidden w-full animate-pulse"><div class="h-32 w-full bg-rwprimary-100"></div><div class="flex flex-col space-y-4 px-2 my-2"><div class="h-8 bg-rwprimary-100 rounded w-full"></div><div class="h-4 bg-rwprimary-100 rounded w-3/4"></div><div class="h-4 bg-rwprimary-100 rounded w-full"></div><div class="h-6 bg-rwprimary-100 rounded w-full"></div></div></div><div class="map-skeleton relative flex flex-col rounded-lg shadow hover:shadow-2xl overflow-hidden w-full animate-pulse"><div class="h-32 w-full bg-rwprimary-100"></div><div class="flex flex-col space-y-4 px-2 my-2"><div class="h-8 bg-rwprimary-100 rounded w-full"></div><div class="h-4 bg-rwprimary-100 rounded w-3/4"></div><div class="h-4 bg-rwprimary-100 rounded w-full"></div><div class="h-6 bg-rwprimary-100 rounded w-full"></div></div></div></div>'
    );

    let paramsArray = serializeArray(this.formTarget);
    let params = paramsArray
      .filter((param) => param.value !== "")
      .map((param) => param.name + "=" + param.value)
      .join("&");
    let url = this.formTarget.action + "?" + params;
    let plainURL = url.replace("/maps", "/");
    Turbo.navigator.history.push(
      new URL(plainURL)
    );
    fetch(url, {
            headers: {
              "Accept": "text/vnd.turbo-stream.html"
            }
          }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html));
  }

  loadNewNeighborhood() {
    this.latMinTarget.value = null;
    this.latMaxTarget.value = null;
    this.lngMinTarget.value = null;
    this.lngMaxTarget.value = null;
    this.centerLatTarget.value = null;
    this.centerLngTarget.value = null;
    this.currentZoomTarget.value = null;
    this.scrollToMarker = true;
    this.map.zoom = 12;
    this.load();
  }

  initMap() {
    const urlParams = new URLSearchParams(window.location.search);
    var zoom = parseInt(this.zoomValue) || 15;
    mapOptions.center = this.centerCoords;
    if (urlParams.has("building_search_form[zoom]")) {
      zoom = parseInt(urlParams.get("building_search_form[zoom]"));
    }
    mapOptions.zoom = zoom;
    mapOptions.minZoom = 9;

      // set marker at Boston lat/lng
      const lat = Number(this.latValue) || 42.3601;
      const lng = Number(this.lngValue) || -71.0589;

      this.map = new google.maps.Map(this.mapTarget, mapOptions);
      if (this.hasLatValue && this.hasLngValue) {
        this.marker = new google.maps.Marker({
          map: this.map,
          position: { lat: lat, lng: lng },
          icon: this.icon(),
        });
      }
      this.map.addListener("click", () => {
        for (const window of this.infoWindows) {
          window.close();
        }
      });

    window.map = this.map;

    window.dispatchEvent(new CustomEvent("mapSet"));

    this.map.addListener("dragend", this.boundsChanged.bind(this));
    this.map.addListener("zoom_changed", this.boundsChanged.bind(this));
    if (this.loadPartnersValue) {
      this.fetchPartners();
    }
  }

  checkSizeAndLoad(e) {
    let width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 1025) {
      e.stopImmediatePropagation();
    } else {
      this.load(e);
    }
  }

  recenterMap(e) {
    if (this.scrollToMarker) {
      this.map.panTo({
        lat: Number(e.detail.lat),
        lng: Number(e.detail.lng),
      });
    }
  }

  boundsChanged(event) {
    if (this.pageValue === "show") {
      return;
    }
    this.scrollToMarker = false;
    let bounds = this.map.getBounds();
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    this.latMinTarget.value = sw.lat();
    this.latMaxTarget.value = ne.lat();
    this.lngMinTarget.value = sw.lng();
    this.lngMaxTarget.value = ne.lng();
    this.zoomValue = this.map.getZoom();
    this.centerLatTarget.value = this.map.getCenter().lat();
    this.centerLngTarget.value = this.map.getCenter().lng();
    this.currentZoomTarget.value = this.map.getZoom();
    this.load();
  }

  removeBuildingCardRows() {
    this.buildingCardRows.forEach(function (row) {
      row.remove();
    });
  }

  icon(hover = false) {
    if (hover) {
      return {
        url: this.hoverPinValue,
        scaledSize: new google.maps.Size(68, 68),
      };
    } else {
      return {
        url: this.pinValue,
        scaledSize: new google.maps.Size(60, 60),
      };
    }
  }

  async urlExists(url) {
    if (url === null) {
      return false;
    }
    const response = await fetch(url, {
      method: "HEAD",
    });
    return response.ok;
  }

  async partnerIcon(url) {
    const exists = await this.urlExists(url);
    if (exists) {
      return {
        url: url,
        scaledSize: new google.maps.Size(60, 60),
      };
    } else {
      return {
        path:
          "M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z",
        strokeColor: "#ec6433",
        fillColor: "#ec6433",
        fillOpacity: 1.0,
        scale: 1.8,
      };
    }
  }

  async setPartnerMarkers(markersData) {
    for (const data of markersData) {
      let marker = new google.maps.Marker({
        map: this.map,
        position: { lat: Number(data.lat), lng: Number(data.lng) },
        title: data.title,
        icon: await this.partnerIcon(data.icon)
      });


        this.partnerMarkers.push(marker);

        const contentString =
          '<div id="content" class="info-window>' +
          '<div id="siteNotice">' +
          "</div>" +
          '<h4 id="firstHeading" class="firstHeading text-rwprimary text-sm font-bold font-[AGBuchBQ]">' +
          data.title +
          "</h4>" +
          '<div id="bodyContent" class="font-[AGBuchBQ]">' +
          "<p>" +
          data.description +
          "</p>" +
          "</div>" +
          "</div>";
        const infoWindow = new google.maps.InfoWindow({
          content: contentString,
        });
        this.infoWindows.push(infoWindow);
        marker.addListener("click", () => {
          infoWindow.open(this.map, marker);
        });
    }
  }

  clearMarkers() {
    for (const marker of this.markers) {
      marker.setMap(null);
    }
    this.markers = [];
  }

  illuminateMarker(e) {
    const el = e.currentTarget;
    const index = parseInt(el.dataset.markerid);
    const marker = this.markers[index];
    if (typeof marker === "undefined") {
      return;
    }
    marker.setIcon(this.icon(true));
    marker.setZIndex(Date.now());
  }

  deluminateMarker(e) {
    const el = e.currentTarget;
    const index = parseInt(el.dataset.markerid);
    const marker = this.markers[index];
    if (typeof marker === "undefined") {
      return;
    }
    marker.setIcon(this.icon(false));
    marker.setZIndex(this.markers.count);
  }

  get buildingCards() {
    return new Array(...this.element.querySelectorAll(".building-card"));
  }

  get buildingCardRows() {
    return new Array(...this.element.querySelectorAll(".card-row"));
  }

  get centerCoords() {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      urlParams.has("building_search_form[center_lat]") &&
      urlParams.has("building_search_form[center_lng]")
    ) {
      return new google.maps.LatLng(
        parseFloat(urlParams.get("building_search_form[center_lat]")),
        parseFloat(urlParams.get("building_search_form[center_lng]"))
      );
    }
    if (
      this.markersDataValue !== "" &&
      JSON.parse(this.markersDataValue).length > 0
    ) {
      let markersData = JSON.parse(this.markersDataValue);
      const lat = Number(markersData[0].lat) || 98.5;
      const lng = Number(markersData[0].lng) || 39;
      return new google.maps.LatLng(lat, lng);
    }
    const lat = Number(this.latValue) || 42.3601;
    const lng = Number(this.lngValue) || -71.0589;
    return new google.maps.LatLng(lat, lng);
  }
}
