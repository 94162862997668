import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];



  toggle(e) {
    if (e.target.getAttribute("type") === "radio") {
      this.buttonTargets.forEach((btn) => {
        if (btn.classList.contains("bg-rwprimary-500")) {
          btn.classList.add("bg-brand-gray-100");
          btn.classList.add("text-brand-gray");
          btn.classList.remove("text-white");
          btn.classList.remove("bg-rwprimary-500");
        } else if (btn.contains(e.target)) {
          btn.classList.remove("bg-brand-gray-100");
          btn.classList.remove("text-brand-gray");
          btn.classList.add("text-white");
          btn.classList.add("bg-rwprimary-500");
        }
      });
    }
  }
}