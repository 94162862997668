import { ValidationController } from "stimulus-validation";

export default class extends ValidationController {
  static targets = ["checkbox", "btn"];

  static rules = {
    message: { presence: { allowEmpty: false } },
  };

  static validators = { validConfirm: { attributes: ['confirm' ]  },
  }

  afterValidate({ el, attr }) {
    if (this.errors.hasAny()) {
      window.dispatchEvent(new CustomEvent("stopLoading"));
    }
    if (this.errors.has(attr)) {
      this.errorMessageEl(el).textContent = this.errorMessage(attr);
      el.classList.add("border-delete-red");
    } else {
      this.errorMessageEl(el).parentNode.removeChild(this.errorMessageEl(el));
      el.classList.remove("border-delete-red");
    }
  }

  errorMessageEl(el) {
    let errorEl = el.closest(".validatable").querySelector(".error-text");
    if (errorEl === null) {
      errorEl = document.createElement("span");
      errorEl.classList = "error-text mt-2 text-sm text-delete-red";
      el.closest(".validatable").appendChild(errorEl);
    }
    return errorEl;
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
  }

  validConfirm({attr, value}) {
    this.btnTarget.disabled = false;
    if (!this.checkboxTarget.checked) {
      this.errors.add(attr, "Confirmation is required");
    }
  }
}
