import { Controller } from "stimulus";
import { dispatchMessage } from "../src/messages";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["display"];
  static values = {
    url: String,
    id: String,
  };

  render(e) {
    let body = e.detail[0].body;
    if (typeof body === "string") {
      this.displayTarget.innerHTML = body;
    } else {
      this.displayTarget.innerHTML = body.innerHTML;
    }
    if (e.detail[0].messages) {
      e.detail[0].messages.forEach((message) => {
        dispatchMessage(message.type, message.msg);
      });
    }
  }

  clear(e) {
    this.displayTarget.innerHTML =
      '<div class="min-h-screen flex flex-col justify-center items-center"><div class="-mt-24 flex justify-center items-center"><div class="animate-spin rounded-full h-20 w-20 border-b-2 border-primary"></div></div></div>';
  }

  fetchHTML(e) {
    if (e.detail.id === this.idValue) {
      Rails.ajax({
        type: "GET",
        url: this.urlValue,
        success: (data) => {
          this.render({
            detail: [data],
          });
        },
      });
    }
  }
}
