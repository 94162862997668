import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select"];
  static values = {
    url: String,
    param: String
  }

  connect() {
    if (this.selectTarget.id === "") {
      this.selectTarget.id = Math.random = Math.random().toString(36);
    }
    let param = this.selectTarget.selectedOptions[0].value;
    if (param !== "") {
      this.makeRequest(param);
    }
  }


  change(event) {
    let param = event.target.selectedOptions[0].value;
    this.makeRequest(param);
  }

  makeRequest(param) {
    fetch(`${this.urlValue}?${this.paramValue}=${param}`, {
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html));
  }
}
