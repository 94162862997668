import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["carousel", "inner", "img"];

  connect() {
    this.baseRatio = 1.333;
    this.ratio = 1.333;
    if (this.hasImgTarget) {
      if (this.isLandscape(this.imgTarget)) {
        this.setDimensions(this.carouselTarget);
      } else {
        let width = this.carouselTarget.clientWidth;
        let height = width / this.baseRatio;
        this.innerTarget.style.width = width + "px";
        this.innerTarget.style.height = height + "px";
      }
    }
  }

  setDimensions(node) {
    let width = node.clientWidth;
    let height = node.clientHeight;
    // set min height
    if (height === 0) {
      height = width / this.baseRatio;
    }
    this.innerTarget.style.width = width + "px";
    this.innerTarget.style.height = height + "px";
    this.ratio = width / height;
  }

  isLandscape(node) {
    let width = node.clientWidth;
    let height = node.clientHeight;
    return width > height;
  }

  resize() {
    let width = this.carouselTarget.clientWidth;
    let height = width / this.ratio;
    this.innerTarget.style.width = width + "px";
    this.innerTarget.style.height = height + "px";
  }

  resizeOnLoad() {
    if (this.hasImgTarget) {
      if (this.isLandscape(this.imgTarget)) {
        this.setDimensions(this.carouselTarget);
      } else {
        let width = this.carouselTarget.clientWidth;
        let height = width / this.baseRatio;

        this.innerTarget.style.width = width + "px";
        this.innerTarget.style.height = height + "px";
      }
    }
  }
}
