import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    frame: String
  }

  connect() {
    // add turbo-relevant headers to GET requests if they come from a turbo-frame
    document.addEventListener("turbo:before-fetch-request", (event) => {
      const { headers } = event.detail.fetchOptions || {};

      if (headers && headers["Turbo-Frame"] === this.frameValue) {

          headers.Accept = ["text/vnd.turbo-stream.html", headers.Accept].join(", ")
      }
    });
  }
}
