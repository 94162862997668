import { ValidationController } from "stimulus-validation";

export default class extends ValidationController {
  static targets = ["submit", "errorMessage", "helpMessage"];


  static rules = {
    name: { presence: { allowEmpty: false } },
    email: { presence: { allowEmpty: false }, email: true },
    phone: { presence: { allowEmpty: false } },
  };

  afterValidate({ el, attr }) {
    if (this.errors.hasAny()) {
      window.dispatchEvent(new CustomEvent("stopLoading"));
    }
    if (this.errors.has(attr)) {
      this.errorMessageEl(el).textContent = this.errorMessage(attr);
      el.classList.add("border-delete-red");
    } else {
      this.errorMessageEl(el).parentNode.removeChild(this.errorMessageEl(el));
      el.classList.remove("border-delete-red");
    }
  }

  errorMessageEl(el) {
    let errorEl = el.closest(".validatable").querySelector(".error-text");
    if (errorEl === null) {
      errorEl = document.createElement("span");
      errorEl.classList = "error-text mt-2 text-sm text-red-600";
      el.closest(".validatable").appendChild(errorEl);
    }
    return errorEl;
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
  }

  updateNotifications(e) {
    let data = e.detail;
    if (data.type === "errors") {
      this.errorMessageTarget.innerHTML = data.message;
    } else if (data.type === "help") {
      // this.helpMessageTarget.innerHTML = data.message;
    }
    // if (data.building === this.idValue) {
    //   this.errorMessageTarget.innerHTML = "";
    //   this.helpMessageTarget.innerHTML = "";
    // }
  }
}
