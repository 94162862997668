import { ValidationController } from "stimulus-validation";

export default class extends ValidationController {
  static targets = ["checkbox", "sum", "total"];

  static validators = { validSelected: { attributes: ['selected' ]  },
  }

  afterValidate({ el, attr }) {
    if (this.errors.hasAny()) {
      window.dispatchEvent(new CustomEvent("stopLoading"));
    }
    if (this.errors.has(attr)) {
      this.errorMessageEl(el).textContent = this.errorMessage(attr);
      el.classList.add("border-delete-red");
    } else {
      this.errorMessageEl(el).parentNode.removeChild(this.errorMessageEl(el));
      el.classList.remove("border-delete-red");
    }
  }

  errorMessageEl(el) {
    let errorEl = el.closest(".validatable").querySelector(".error-text");
    if (errorEl === null) {
      errorEl = document.createElement("span");
      errorEl.classList = "error-text mt-2 text-sm text-red-600";
      el.closest(".validatable").appendChild(errorEl);
    }
    return errorEl;
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
  }

  validSelected({attr, value}) {
    let count = 0;
    this.checkboxTargets.forEach((target) => {
      if (target.checked) {
        count += 1;
      }
    });
    if (count !== 1) {
      this.errors.add(attr, "Please select exactly 1 building");
    }
  }

  sum() {
    let sum = 0;
    this.sumTargets.forEach((el) => {
      let num = Number(el.value);
      sum += num;
    })
    this.totalTarget.value = sum.toFixed(2);
  }
}