import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    placeholder: String
  }

  setDateType(e) {
    this.inputTarget.type = "date";
  }

  resetInputType(e) {
    if (this.inputTarget.value === "") {
      this.inputTarget.type = "text";
    }
  }
}