import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {
  static targets = ["carousel", "inner", "img"];

  connect() {
    new Splide(this.element, {
      autoplay: true,
      interval: 6000,
      pagination: false,
      type: "loop"
    }).mount();
  }
}