import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["indicator", "content"];

  connect() {
    if (this.contentTarget.classList.contains("hidden")) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  open() {
    this.indicatorTarget.innerHTML = "-";
    this.contentTarget.classList.remove("hidden");
    this.isOpen = true;
  }

  close() {
    this.indicatorTarget.innerHTML = "+";
    this.contentTarget.classList.add("hidden");
    this.isOpen = false;
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }
}
