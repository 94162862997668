import { Controller } from "stimulus"

export default class extends Controller {

  clearSrc() {
    this.element.src = '';
  }

  setDisplayContents() {
    this.element.classList.add("contents");
  }
}
