import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggle", "background", "input", "btn"];

  connect() {
    this.enabled = this.inputTarget.checked;
  }

  toggle(e) {
    this.enabled = !this.enabled;
    this.backgroundTarget.classList.toggle("bg-rwprimary");
    this.backgroundTarget.classList.toggle("bg-gray-200");
    this.toggleTarget.classList.toggle("translate-x-5");
    this.toggleTarget.classList.toggle("translate-x-0");

    if (e.target == this.btnTarget) {
      this.inputTarget.checked = !this.inputTarget.checked;
      this.inputTarget.dispatchEvent(new Event("change"));
    }
  }
}
