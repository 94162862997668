import { Controller } from "stimulus";
import intlTelInput from "intl-tel-input";

export default class extends Controller {

  connect() {
    if (typeof this.iti === "undefined") {
      this.iti = intlTelInput(this.element, {
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js",
        hiddenInput: "phone",
        initialCountry: "US",
        customPlaceholder: function() {
          return "Phone *"
        }
      });
    }
  }

  validate() {
    if (this.element.value.trim()) {
      if (this.iti.isValidNumber()) {
        this.errorMessageEl(this.element).remove();
      } else {
        let errorCode = this.iti.getValidationError();
        let errorText = this.errorMap[errorCode] || "Invalid Phone Number";
        this.errorMessageEl(this.element).textContent = errorText;
      }
    }
  
  }

  errorMessageEl(el) {
    let errorEl = el.closest(".validatable").querySelector(".error-text");
    if (errorEl === null) {
      errorEl = document.createElement("span");
      errorEl.classList = "error-text mt-2 text-sm text-red-600";
      el.closest(".validatable").appendChild(errorEl);
    }
    return errorEl;
  }

  get errorMap() {
    // the index maps to the error code returned from getValidationError - see readme
    return ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
  }
}