import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["shadowEl", "bottom", "link"];

  connect() {
    this.setShadowHeight();
  }

  setShadowHeight() {
    let shadowTop = this.shadowElTarget.getBoundingClientRect().top;
    let bottomElBottom = this.bottomTarget.getBoundingClientRect().bottom;
    this.shadowElTarget.style.height = bottomElBottom - shadowTop + "px";
    let linkTop = this.linkTarget.getBoundingClientRect().top;
    this.linkTarget.style.height = bottomElBottom - linkTop + "px";
  }
}
